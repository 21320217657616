/* Home Section Animation */
.home-section{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-intro h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.home-intro p.lead {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #555;
  text-align: center;
}

/* Welcome Animation */
.welcome-animation {
  top: 0;
  left: 0;
  width: 60%;
  height: 30%; /* Adjust the height as needed */
  z-index: -1;
}

/* For Button in Home */
.btn-hireMe {
  margin-top: 15px;
  margin-right: 10px;
  color: purple;
  border: purple solid 1px;
  border-radius: 10px 0 0 10px; /* Rounded border on the left side */
  padding: 10px 15px; /* Adjust padding as needed */
  text-decoration: none;
}
.btn-resume{
  margin-top: 15px;
  margin-right: 10px;
  color: purple;
  border: purple solid 1px;
  border-radius: 0 10px 10px 0; /* Rounded border on the left side */
  padding: 10px 15px; /* Adjust padding as needed */
  text-decoration: none;
}

.btn-hireMe:hover {
  margin-top: 15px;
  margin-right: 10px;
  color: rgb(239, 225, 134);
  background-color: purple;
  border-radius: 10px 0 0 10px; /* Rounded border on the left side */
  padding: 10px 15px; /* Adjust padding as needed */
  text-decoration: none;
}
.btn-resume:hover {
  margin-top: 15px;
  margin-right: 10px;
  color: rgb(239, 225, 134);
  background-color: purple;
  border-radius: 0 10px 10px 0; /* Rounded border on the left side */
  padding: 10px 15px; /* Adjust padding as needed */
  text-decoration: none;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 15px; /* Adjust the gap between icons */
}
.social-links a {
  text-decoration: none; /* Remove default text decoration */
  color: inherit; /* Inherit the color from the parent */
}

/* If you want to style the icons, you can do so here */
.social-links a svg {
  font-size: 24px; /* Example font size for icons */
  /* Add additional styles if needed */
}

.social-links a svg:hover {
  font-size: 35px; /* Example font size for icons */
  color: rgb(3, 83, 255);
}
/* Center the content */
.temp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/* Add this CSS code to your stylesheet */

/* Style for the card */
.card {
  width: 18rem;
  margin: 20px; /* Add margin */
  padding: 15px; /* Add padding */
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style for the card image */
.card-img-top {
  max-height: 200px; /* Adjust image height */
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Style for the card title */
.card-title {
  text-align: center;
  font-size: 1.25rem;
  margin-top: 10px;
}

/* Style for the card text */
.card-text {
  font-size: 1rem;
  margin-top: 10px;
  color: #555;
}

/* Style for the buttons */
.card-body .btn {
  margin-top: 15px;
  margin-right: 10px;
}

/* CSS For About */

.about {
  position: relative;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 4px 50px rgb(75, 115, 193);
  margin: 25px;
  padding: 30px;
  z-index: 3;
}

.skill-item {
  display: flex;
  gap: 10px;
  font-size: 50px;
}
.about-intro {
  display: flex;
  text-align: justify;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.profile-image {
  width: 150px;
  height: 150px;
  border: 2px solid black;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.about-intro p {
  font-size: 16px;
  line-height: 1.6;
}

/* Education */

.resume-item {
  margin-left: 100px;
}

/* Project */

.experience-section {
  position: relative;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 4px 50px rgb(75, 115, 193);
  margin: 25px;
  padding: 30px;
  z-index: 2;
}

/* Contact Section */
.contact-section {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 4px 50px rgb(75, 115, 193);
  margin: 25px;
  padding: 30px;
  z-index: 1;
}

.contact-section-links {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  text-align: center; */
  margin-top: 2%;
}

.contact-section-links p,
.contact-section-links a {
  margin-bottom: 10%;
  font-size: 16px;
  color: #333;
}

.contact-section-links a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.contact-section-links a:hover {
  color: rgb(3, 83, 255);
}

.contact-form {
  border-radius: 10px;
  /* background-color:  rgb(201, 234, 13); */
  box-shadow: 0px 4px 10px rgb(75, 115, 193);
  padding: 30px;
}

.contactWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  resize: none;
}

.contact-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.project-card-icons {
  display: flex;
  justify-content: center;
  gap: 5px;
}

@media (max-width: 768px) {
  
  /* Eductaion */
  .resume-item {
    margin-left: 0px;
  }
  .about-education-title {
    text-align: center;
  }
  .size-reducer {
    font-size: 14px;
  }

  /* Skill */
  .skill-title {
    text-align: center;
  }
  .skill-sub-heading {
    text-align: center;
    font-size: 90%;
  }
  .skill-item {
    flex-wrap: wrap; /* Allow skill items to wrap */
    justify-content: center; /* Center items horizontally */
    margin-top: 10px; /* Adjust margin for spacing */
  }

  .skill-item li {
    margin: 5px; /* Add spacing between skill items */
  }

  /* For Contact Form */
  .contactWrapper {
    display: flex;
    flex-direction: column;
  }
  .contact-section {
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center content horizontally */
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: flex;
    justify-content: center;
    gap: 150%; /* Adjust the gap between icons */
  }

  .mobile-only svg {
    margin-top: 90%;
    font-size: 24px; /* Example font size for icons */
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
  }
  .mobile-only a svg:hover {
    font-size: 35px;
    color: rgb(3, 83, 255);
  }

  .contact-form {
    margin-top: 15px; /* Add spacing between sections */
  }

  /* About Intro */
  .about-intro {
    display: flex;
    flex-direction: column;
  }
  /* Intrest */
  .intrest-section-title {
    text-align: center;
  }
  .intrest-section {
    text-align: justify;
  }

  /* Experiance */
  .experience-section-intro {
    text-align: justify;
  }
  .card {
    width: 100%; /* Make cards full width on smaller screens */
    margin: 10px auto; /* Center cards horizontally */
    text-align: justify;
  }

  .card-img-top {
    max-height: 150px; /* Adjust image height on smaller screens */
  }

  .card-title {
    font-size: 1rem; /* Reduce font size for card title on smaller screens */
    text-align: center;
  }

  .card-text {
    font-size: 0.875rem; /* Reduce font size for card text on smaller screens */
  }

  .card-body .btn {
    margin-top: 10px;
    margin-right: 5px;
    font-size: 0.875rem; /* Reduce font size for buttons on smaller screens */
  }
}

@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
  .intrest-section {
    text-align: justify;
    text-indent: 90px;
    margin-left: 60px;
  }
  .skill-sub-heading {
    margin-left: 100px;
  }
  .skill-icon {
    margin-left: 120px;
  }
  .home-intro{
  
  padding: 1% 25% 1% 25%;
}
}